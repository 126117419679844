import { FC, ReactNode } from 'react';

import { LazyMotion } from 'framer-motion';
import dynamic from 'next/dynamic';

import { ApiSettings } from '@hultafors/shared/types';

import { GlobalFields } from '@hultafors/solidgear/types';

const SWRProvider = dynamic(() =>
  import('@hultafors/shared/context').then((module) => module.SWRProvider),
);

const GlobalProvider = dynamic(() =>
  import('../global-context/global-context').then((mod) => mod.GlobalProvider),
);
const FavoritesProvider = dynamic(() =>
  import('../favorites-context/favorites-context').then(
    (mod) => mod.FavoritesProvider,
  ),
);
const CartProvider = dynamic(() =>
  import('../cart-context/cart-context').then((mod) => mod.CartProvider),
);
const GuideProvider = dynamic(() =>
  import('../guide-context/guide-context').then((mod) => mod.GuideProvider),
);

const MarketPickerProvider = dynamic(() =>
  import('@hultafors/shared/context').then((mod) => mod.MarketPickerProvider),
);

const lazyMotionFeatures = () =>
  import('./lazy-motion-features').then((res) => res.default);

export interface ApplicationProviderProps {
  children?: ReactNode;
  value: GlobalFields & {
    settings: ApiSettings;
  };
}
export const ApplicationContextProvider: FC<ApplicationProviderProps> = ({
  value: { settings, ...value },
  children,
}) => {
  const active = [settings?.hostname, settings?.language?.urlPrefix]
    .filter(Boolean)
    .join('/');
  return (
    <SWRProvider>
      <LazyMotion features={lazyMotionFeatures}>
        <GlobalProvider value={value} settings={settings}>
          <GuideProvider>
            <CartProvider settings={settings}>
              <FavoritesProvider settings={settings}>
                <MarketPickerProvider
                  label={value?.global?.changeLanguage ?? ''}
                  markets={value?.global?.languages || []}
                  active={active}
                >
                  {children}
                </MarketPickerProvider>
              </FavoritesProvider>
            </CartProvider>
          </GuideProvider>
        </GlobalProvider>
      </LazyMotion>
    </SWRProvider>
  );
};
